import axios from 'axios';
import { FYND_ENGAGE_RESOURCE_ROUTE, getBaseUrl } from '../utils/url.utils';
const url = getBaseUrl();

const headers = {
  // Add more headers if necessary
  'Content-Type': 'application/json',
};

const buildUri = (endpoint, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return `${url}/${FYND_ENGAGE_RESOURCE_ROUTE}${endpoint}${queryString ? `?${queryString}` : ''}`;
};

export const rewardsCartDetail = (payload) => {
  const uri = buildUri('/cart', { cart_id: payload.cart_id, user_id: payload.user_id });
  return axios.get(uri);
};

export const fetchUserCartOnly = (payload) => {
  const uri = buildUri('/cart/user', { cartId: payload.cart_id, i: payload.i, b: payload.b });
  return axios.get(uri, { headers });
};

export const rewardDetailsOfUser = async (payload) => {
  try {
    const queryParams = { i: payload.i, b: payload.b, ...(payload.cart_id && { cartId: payload.cart_id }) };
    const uri = buildUri('/cart/details', queryParams);
    return await axios.get(uri, { headers });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const addItemsInCart = (addItemsPayload) => {
  const uri = buildUri('/cart');
  return axios.post(uri, { data: addItemsPayload }, { headers });
};

export const updateItemsInCart = (updateItemsPayload) => {
  const uri = buildUri('/cart');
  return axios.put(uri, { data: updateItemsPayload }, { headers });
};

export const removeItemsInCart = (removeItemsPayload) => {
  const uri = buildUri('/cart');
  return axios.put(uri, { data: removeItemsPayload }, { headers });
};

export const deleteCart = (deleteCartPayload) => {
  const uri = buildUri('/cart/archive');
  return axios.put(uri, { data: deleteCartPayload }, { headers });
};

export const engageEarnDetailsOnCart = (payload) => {
  const queryParams = { i: payload.i, b: payload.b, ...(payload.cart_id && { cartId: payload.cart_id }) };
  const uri = buildUri('/cart/earns', queryParams);
  return axios.get(uri, { headers });
};

export const getUserReferralInfo = () => {
  const uri = buildUri('/user/referral');
  return axios.get(uri, { headers });
};

export const getUserTransactionHistory = () => {
  const uri = buildUri('/user/points/history');
  return axios.get(uri, { headers });
};

export const applyOrRemoveCartInjection = (payload) => {
  const queryParams = { ...(payload.cart_id && { cart_id: payload.cart_id }), b: true };
  const uri = buildUri('/cart/points', queryParams);
  return axios.post(uri, { points: payload.points }, { headers });
};

export const createDynamicTag = (body) => {
  const uri = buildUri('/cart/create');
  return axios.post(uri, body, { headers });
};

export const updateDynamicTag = (body, cart_id) => {
  const uri = buildUri('/cart/update', { id: cart_id });
  return axios.put(uri, body, { headers });
};

export const deleteDynamicTag = (body, cart_id) => {
  const uri = buildUri('/cart/delete', { id: cart_id });
  return axios.delete(uri, { data: body, headers });
};

export const siteMap = () => {
  const uri = buildUri('/coupons/blog-sitemap.xml');
  return axios.get(uri, { headers });
};
