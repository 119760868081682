import EngagePointsView from "@/components/engage-points/engagePoints.vue";
// register the FPI object with new extension ID
window.FPI.extension.register("#ext-fynd-engage", {
  mounted(element) {
    // mount the extension / Vue app on the given ID
    window.FPI.extension.mountApp({
      element,
      component: EngagePointsView,
    });
  },
});
