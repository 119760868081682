function getBaseUrl() {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
}

const TAG_NAME = "fynd-engage";

const FYND_ENGAGE_RESOURCE_ROUTE = "ext/fynd-engage/application/engage/v1.0"


module.exports = {
  getBaseUrl,
  TAG_NAME,
  FYND_ENGAGE_RESOURCE_ROUTE
};
